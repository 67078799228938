.logo {
  position: absolute;
  font-family: $font-2;
  font-size: 1.2rem;
  user-select: none;

  &:after {
    content: "";
    height: 45px;
    width: 45px;
    /*border: 1px $color-2 solid;
    border-radius: 50%;*/
    background: url("../../assets/imgs/METEOR.gif");
    position: absolute;
    top: -7px;
    left: -40px;
    z-index: -1;
  }
}